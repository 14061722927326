<template>
  <div class="h-min">
    <div class="d-flex justify-content-center">
      <b-row class="mt-3 col-12 col-lg-8 col-xl-5 pb-10 h-auto">
        <b-alert v-if="viewAlertEdit()" :show="true" variant="warning" class="w-100 text-center p-2 mb-1">{{$t('creator.unable_to_edit_pitch').find(value => value.value === pitch.status).text}}</b-alert>
        <b-col class="col-12 text-center">
          <h1 class="mb-1">{{ $t('creator.previewPitch') }}</h1>
          <p>{{ $t('creator.pitchBuyer') }}</p>
        </b-col>
        <b-col class="col-12">
          <hr/>
          <div class="mb-2">
            <h4>Pitch:</h4>
            <span>{{ pitch.pitch_description }}</span>
            <div v-if="(pitch.video_url && pitch.video_url.length > 0) || pitch.video_pitch" class="d-flex justify-content-center mt-1">
              <video
                :src="(pitch.video_url && pitch.video_url.length > 0) ? verifyAsset(pitch.video_url) : verifyAsset(pitch.video_pitch)" 
                controls 
                class="video-preview-iframe-casting"
              ></video>
            </div>
          </div>

          <div class="mb-2">
            <h4>{{ $t('creator.askingPrice') }}</h4>
            <p v-if="casting_call.type_compensation === 'negotiable'">$ {{ numberFormat(parseInt(pitch.desired_payment), true) }}</p>
            <p v-else class="avenir-medium">{{ $t('creator.negotiableOnly') }}</p>
          </div>

          <div>
            <h4>{{ $t('creator.ConnectionsPitch') }}</h4>
            <div v-for="user_network in pitch.user_networks" :key="user_network.uuid" class="d-flex">
                <b-avatar variant="ligth">
                  <b-img :src="getIconWhite(user_network.network)" :class="getIconColor(user_network.network)" fluid></b-img>
                </b-avatar>
              <b-col>
                <span>{{ capitalize(user_network.network) }}</span>
                <span class="font-link d-flex">{{ `https://www.${user_network.network}.com/@${user_network.username}` }}</span>
                <hr class="mb-1"/>
              </b-col>
            </div>
          </div>

          <div v-if="pitch.files.length > 0">
            <h4>{{ $t('creator.attachmentsPitch') }}</h4>
            <b-col v-for="(attachment) in pitch.files" :key="attachment.uuid" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <video 
                  v-if="checkVideo(attachment)"
                  class="avatar-preview-sample cursor-pointer"
                  height="35"
                  :src="attachment.asset_url && attachment.asset_url.length > 0 ? verifyAsset(attachment.asset_url) : verifyAsset(attachment.file)"
                  @click="clickVideo(attachment)"
                  muted
                ></video>
                <strong v-if="checkVideo(attachment)"><b-link @click="clickVideo(attachment)">{{ $t('creator.upVideo') }}</b-link></strong>
                <b-avatar 
                  v-if="!checkVideo(attachment)"
                  button
                  @click="clickVideo(attachment)"
                  :src="attachment.asset_url && attachment.asset_url.length > 0 ? verifyAsset(attachment.asset_url) : verifyAsset(attachment.file)"
                  square
                  class="avatar-preview-sample"
                  :icon="isPDF(attachment.asset_url) ? 'file-text' : 'camera-video'"
                ></b-avatar>
                <strong v-if="!checkVideo(attachment)"><b-link @click="clickVideo(attachment)">{{ isPDF(attachment.asset_url) ? $t('creator.upPDF') : $t('creator.upImage') }} </b-link></strong>
              </div>
            </b-col>
          </div>

         <div v-if="pitch.answers.length > 0" class="mt-1">
            <h4>{{ $t('creator.surveyAnswers') }}</h4>
            <div v-for="(answer, index) in pitch.answers" :key="answer.uuid">
              <h5>{{ index + 1 }}. {{ answer.question.question }}</h5>
              <p v-if="answer.question.type_question === 'radio' || answer.question.type_question === 'free_text'" class="text-muted">{{ answer.answer }}</p>
              <p v-if="answer.question.type_question === 'checkbox'" class="text-muted">{{ answer.answer.join(', ') }}</p>
              <div v-if="answer.question.type_question === 'media'">
                <p><a :href="verifyAsset(answer.media)" target="_blank">{{ $t('creator.viewArchive') }}</a></p>
              </div>
            </div>
          </div>
          <div>
          <h4 class="mt-1">{{ $t('creator.contactEmail') }}</h4>
            <span>{{ pitch.email_contact }}</span>
          </div>
          <div  v-if="!viewAlertEdit()">
            <div class="d-flex flex-column">
              <div class="d-flex mb-1 justify-content-center">
                <b-button variant="light" class="button-free-report d-flex mr-1" @click="open_edit_modal = true; update_edit_modal = !update_edit_modal">{{ $t('creator.edit') }}</b-button>
                <b-button :disabled="!validationSteps()" variant="blue-button-next-step" class="button-free-report blue-button-next-step" @click="save()">{{ $t('creator.submitPitch') }}</b-button>
              </div>
              <small class="text-muted text-center">{{ $t('creator.textSmall') }}</small>
            </div>
          </div>
        </b-col>

      </b-row>
     

      <modal-brandme :show_modal="open_edit_modal" :key="update_edit_modal">
        <template #title>
          <strong>{{ $t('creator.selectEdit')}}</strong>
        </template>
        <template #body>
          <div 
            :class="`lists-edit-steps ${getEditAvaible()[index].disabled ? 'disabled-list-edit' : ''}`" 
            v-for="(edit_option, index) in getEditAvaible()" 
            :key="index"
            @click="!getEditAvaible()[index].disabled ? $router.push({ name: 'stepper-send-pitch', params: {slug: casting_call.slug, step: edit_option.route, pitch_uuid: pitch.uuid, is_editing: true}}) : ''"
          >
            <div>
              {{edit_option.name}}
            </div>
            <div>
              <small :class="`${edit_option.required && !edit_option.exists ? '' : 'd-none'} text-danger m-05`"><feather-icon :icon="edit_option.required ? 'AlertCircleIcon' : ''" class="text-dange m-05"/>{{ $t('creator.requiredStep') }}</small>
              <feather-icon :icon="edit_option.exists ? 'CheckCircleIcon' : 'CircleIcon'" :class="edit_option.exists ? 'text-success' : 'text-secondary'"></feather-icon>
            </div>
          </div>
        </template>
      </modal-brandme>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BImg,
  BAlert,
} from 'bootstrap-vue';
import modalBrandme from '@/views/components/modal/ModalBrandme.vue';
import { getIconWhite, getIconColor } from '@/libs/utils/icons';
import { verifyAsset } from '@/libs/utils/urls';
import { numberFormat, capitalize } from '@/libs/utils/formats'
import { showConfetti } from '@/libs/utils/others';
import pitch_service from '@/services/pitch_service'

export default {
  name: 'preview',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BAvatar,
    modalBrandme,
    BAlert,
  },
  data() {
    return {
      capitalize,
      numberFormat,
      verifyAsset,
      getIconWhite,
      getIconColor,
      open_edit_modal: false,
      update_edit_modal: false,
      is_editing: this.$route.params.is_editing,
      is_redirecting: false,
    }
  },
  created() {
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    isPDF(name) {
      return name ? name.toLowerCase().endsWith('.pdf') : false;
    },
    viewAlertEdit() {
      if (this.pitch.status === "submitted" || this.pitch.status === "approved" || this.pitch.status === "finished") return true 
      else return false 
    },
    checkVideo(attachment) {
      const extension = attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url.split('.')[attachment.asset_url.split('.').length - 1] : attachment.file.split('.')[1]
      return (extension === 'mp4' || extension === 'mov')
    },
    clickVideo(attachment) {
      window.open(verifyAsset(attachment.asset_url), '_blank')
    },
    save() {
      pitch_service.submitPitch(this.pitch.uuid).then(response => {
        localStorage.removeItem('stepperInfo')
        this.$emit('updatePitch', response)
        this.$swal({
          title: this.$t('campaigns.pitchSubmit'),
          html: this.$t('campaigns.successPitch'),
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-success'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(() => {
          showConfetti('explosion')
        })
        this.$router.push({name: 'my_work', params: {section: 'pitches'}})
        this.is_redirecting = true;
        this.$root.$emit('quit_navbar_white');
      })
    },
    validationSteps() {
      if (this.pitch.type_pitch === 'record_video') {
        if (
          (this.pitch.video_pitch || this.pitch.video_url) &&
          this.pitch.type_pitch === 'record_video' &&
          this.casting_call.type_compensation === 'negotiable' ? this.pitch.desired_payment !== '0.00' : true &&
          this.pitch.user_networks.length > 0 &&
          this.pitch.email_contact
        ) {
          return true;
        } else {
          return false;
        }
      } 
      if (this.pitch.type_pitch === 'video') {
        if (
          (this.pitch.video_pitch || this.pitch.video_url) &&
          this.pitch.type_pitch === 'video' &&
          this.casting_call.type_compensation === 'negotiable' ? this.pitch.desired_payment !== '0.00' : true &&
          this.pitch.user_networks.length > 0 &&
          this.pitch.email_contact
        ) {
          return true;
        } else {
          return false;
        }
      } 
      if (this.pitch.type_pitch === 'text') {
        if (
          this.pitch.pitch_description &&
          this.casting_call.type_compensation === 'negotiable' ? this.pitch.desired_payment !== '0.00' : true &&
          this.pitch.user_networks.length > 0 &&
          this.pitch.email_contact
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    getEditAvaible() {
      return [
        {name: this.$t('creator.pitchStep'), route: 'select_pitch_type', exists: true, required: true},
        {name: this.$t('creator.recordVideo'), route: 'video_record', exists: this.pitch.video_pitch && this.pitch.type_pitch === 'record_video', disabled:  this.pitch.type_pitch !== 'record_video', required: this.pitch.type_pitch === 'record_video'},
        {name: this.$t('creator.videoUp'), 
          route: 'upload_video',
          exists: (this.pitch.video_pitch && this.pitch.type_pitch === 'video') || (this.pitch.video_url && this.pitch.type_pitch === 'video'),
          disabled: this.pitch.type_pitch !== 'video',
          required: this.pitch.type_pitch === 'video'},
        {name: this.$t('creator.descriptionAdd'), route: 'add_description', exists: this.pitch.pitch_description, required: this.pitch.type_pitch === 'text'},
        {name: this.$t('creator.questionStep'), route: 'questions', exists: this.pitch.answers.length > 0, disabled: this.casting_call.questions && this.casting_call.questions.length === 0},
        {name: this.$t('creator.desiredPay'), route: 'desired_payment', exists: this.pitch.desired_payment !== '0.00', required: this.casting_call.type_compensation === 'negotiable', disabled: this.casting_call.type_compensation === 'fixed'},
        {name: this.$t('creator.connectionsStep'), route: 'connections', exists: this.pitch.user_networks.length > 0, required: true},
        {name: this.$t('creator.contactStep'), route: 'contact', exists: this.pitch.email_contact, required: true},
        {name: this.$t('creator.attachmentsStep'), route: 'attachments', exists: this.pitch.files.length > 0},
      ]
    }
  },
}
</script>
<style scoped>
.m-05 {
  margin-right: 5px;
}
.h-min {
  min-height: 100vh;
}

.button-free-report {
  height: 40px;
  margin-top: 1em;
}
.pb-10 {
  padding-bottom: 8em;
}
.lists-edit-steps {
  padding: 1em;
  border-bottom: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
}
.lists-edit-steps:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.disabled-list-edit {
  cursor: initial !important;
  color: #dedede;
}
.disabled-list-edit:hover {
  background-color: white;
}
.font-link {
  font-size: .86rem;
  color: #495057
}
.avatar-preview-sample {
  margin-right: 1em;
  width: 45px !important;
  height: 45px !important;
  object-fit: cover !important;
  border-radius: 1em !important;
}
.video-preview-iframe-casting {
  width: 60%;
  border-radius: 1em;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
</style>
