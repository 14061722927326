<template>
  <div>
    <div class="header-current">{{ $t('memberships.detailMembership') }}</div>
    <div class="main-container-current-membership">
      <div>
        <p class="name-plan">{{  $t(`creator.displayNameMembership.${plan.display_name}`) }} {{ period_trial }}</p>
        <span class="w-100 d-block">{{ $t('memberships.yourPlan') }} ({{ status_plan.interval === 'year' ? $t('memberships.billedYearly') : $t('memberships.billedMontly')}})</span>
        <span v-if="!is_free && !status_plan.cancel_at_period_end" class="d-flex align-items-center">
          {{ $t('memberships.nextBill')}}: 
          <span v-if="loaded_service" class="d-block ml-05">{{ getDatev2(new Date(status_plan.next_charge)) }}</span>
          <b-skeleton class="skeleton-span-time-next-bill" v-else></b-skeleton>
        </span>
        <span v-if="!is_free && status_plan.cancel_at_period_end" class="d-flex align-items-center">
          {{ $t('memberships.subscriptionEnd')}}: 
          <span v-if="loaded_service" class="d-block ml-05">{{ getDatev2(new Date(status_plan.current_period_end)) }}</span>
          <b-skeleton class="skeleton-span-time-next-bill" v-else></b-skeleton>
        </span>

        
      </div>
      <div>
        <b-button v-if="plan.name !== 'IA +' && plan.name !== 'Premium +' && !status_plan.cancel_at_period_end"  @click="openModal" variant="blue-button" class="blue-button ml-1">{{ $t('memberships.updatePlan') }}</b-button>
        <b-button @click="toggleModalStripe(true)" variant="blue-button" class="blue-button m-1 d-flex" v-if="!is_free && !status_plan.cancel_at_period_end">{{ $t('creator.changePayment') }}</b-button>
        <b-button class="ml-1" variant="flat-danger" @click="cancelSuscription" v-if="!is_free && !status_plan.cancel_at_period_end">{{ $t('memberships.cancelPlan') }}</b-button>
        <b-button @click="reactiveSuscription" variant="blue-button" class="blue-button mt-1 ml-1 d-flex" v-if="!is_free && status_plan.cancel_at_period_end">{{ $t('creator.reativePlan') }}</b-button>
      </div>

      <div class="w-100 mt-1">
        <span>{{ status_plan.interval === 'year' ? $t('memberships.totalYear') : $t('memberships.totalMonth')}}</span>
        <p class="price m-0">
          $ {{ is_free ? '0.00' : status_plan.interval === 'year' ? plan.price_year : plan.price_month}}</p>
      </div>
    </div>
    <modal-membership-group
      v-if="open_modal_plans_group"
      :open_modal_plans_group="open_modal_plans_group" 
      :key="update_modal"
      :month_dad="true"
    />
    <modal-stripe :key="change_modal" :show_modal="modal_stripe" @hidden="toggleModalStripe(false)">
      <template #body>
    </template>
    </modal-stripe>
  </div>
</template>

<script>
import {
  BButton,
  BSkeleton,
} from 'bootstrap-vue'
import { getUserData, setPermissions, setuserData } from '@/libs/utils/user';
import { getDatev2, daysUntil } from '@/libs/utils/times';
import service from '@/services/proposals'
import abilities from '@/libs/acl/abilities'
import store from '@/store';

export default {
  name: 'CurrentMembership',
  components: {
    BButton,
    BSkeleton,
    ModalMembershipGroup: () => import('@/views/components/modal/ModalMembershipGroup.vue'),
    ModalStripe: () => import('@/views/components/modal/ModalStripe.vue'),
  },
  props: {
    status_plan_dad: {
      type: Object,
      default: () => {
        return {}
      }
    },
    loaded_service: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    plan() {
      return getUserData().current_plan;
    },
    is_free() {
      return this.plan.name.toLowerCase() === 'free'
    },
    period_trial() {
      const period = store.getters['main_store/trial_period'];
      const days = daysUntil(period.current_period_end)

      if (!period.trial) return ''
      else return `( ${this.$t('trialOf')} ${days} ${this.$t('preview.days')})`
    }
  },
  data() {
    return {
      modal_stripe: false,
      change_modal: false,
      setuserData,
      setPermissions,
      getDatev2,
      status_plan: {},
      open_modal_plans_group: false,
      update_modal: 0,
    };
  },
  created() {
    if (this.loaded_service) this.status_plan = {...this.status_plan_dad}

    this.openModalPaymentFromQueryAction(this.$route.query?.open_update_payment)
  },
  methods: {
    toggleModalStripe(isOpen) {
      this.modal_stripe = isOpen;
      this.change_modal = !this.change_modal;
    },
    openModal() {
      this.open_modal_plans_group = true;
      this.update_modal += 1;
    },
    cancelSuscription() {
      this.$swal({
        title: this.$t('creator.cancelMembership'),
        text: this.$t('creator.youSureCancel'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('creator.yesIam'),
        cancelButtonText: this.$t('creator.noIam'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          service.cancelPlan().then(response => {
            this.setUser(response)
          });
        }
      });
    },
    reactiveSuscription() {
      this.$swal({
        title: this.$t('creator.reactiveMembership'),
        text: this.$t('creator.youSureReactive'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('creator.yesIam'),
        cancelButtonText: this.$t('creator.noIam'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          service.reactivePlan().then(response => {
            this.setUser(response)
          });
        }
      });
    },
    setUser (data) {
      setPermissions(data.user);
      localStorage.removeItem('userData');
      const read_ability = abilities.getAbilitie(data.user)

      setuserData(data, read_ability)
      this.$ability.update(read_ability)
      this.$store.commit('appConfig/UPDATE_IS_VERIFIED', data.user.is_verified)
      window.location.reload()
    },
    openModalPaymentFromQueryAction(open_update_payment) {
      if (open_update_payment) this.toggleModalStripe(true)  
    }
  },
  watch: {
    loaded_service(val) {
      if (val) this.status_plan = {...this.status_plan_dad}
    },
    '$route.query'(val) {
      this.openModalPaymentFromQueryAction(val);
    },
    modal_stripe(val) {
      const query_data = this.$route.query?.open_update_payment;
      if (!val && query_data) {
        this.$router.replace({name: 'pages-account-setting', params: {section: 'membership'}}).catch(() => {});
      } 
    }
  }
}
</script>
<style scoped lang="scss">
.ml-05 {
  margin-left: 0.5em;
  
}
.main-container-current-membership {
  width: 100%;
  border: 1px solid #cccccc83;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  padding: 1.5em;
  flex-wrap: wrap;
  .skeleton-span-time-next-bill {
    width: 100px;
    margin: 0;
    margin-left: 0.5em;
  }
  .price {
    color: #348267;
    font-size: 26px;
  }

  span {
    color: #6c757d;
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'avenir-medium';
  }
  .name-plan {
    font-size: 16px;
  }
}
.header-current {
  background-color: #fafafa;
  width: 100%;
  border: 1px solid #cccccc83;
  border-top-right-radius: 0.5em;
  border-top-left-radius: 0.5em;
  padding: 8px 16px;
  font-size: 16px;
}
</style>