<template>
  <b-card
    body-class="p-0"
    class="container-card-category m-0"
    :style="getWidth()"
    @click="clickCard(proposal)"
  >
    <div class="container-main-image" @mouseover="mouseHover" @mouseleave="mouseLeave">
      <b-badge pill :class="`badge-network ${getIconColor(proposal.user_network.network)}`" :variant="getIconColor(proposal.user_network.network)">
        <b-img :src="getIconWhite(proposal.user_network.network)" class="card-icon-network-x"/>
        <span class="ml-04 followers-inner-badge">{{getFormat(proposal.user_network.followers)}}</span>
      </b-badge>
      <div class="height-310">
        <transition :name="first_time_transition ? 'fade' : ''">
          <div :key="current_image">
            <b-img
              v-if="!isVideo(current_image)"
              class="container-img image-animation-zoom" 
              :src="current_image" 
              @error="errorLoad"
            />
            <video 
              v-else
              muted 
              ref="video_animation_proposal" 
              :src="current_image" 
              class="container-img" 
              @timeupdate="checkVideoEnd"
              @mouseenter="playVideo"
              @mouseleave="pauseVideo"
            ></video>
          </div>
        </transition>
        <div class="actions-proposal" v-if="user_data !== null && user_data.is_superuser">
          <b-button variant="button-actions-proposal" class="button-actions-proposal">
            <feather-icon icon="HeartIcon" class="text-danger" size="17"></feather-icon>
          </b-button>
          <b-button variant="button-actions-proposal" class="button-actions-proposal mt-1">
            <feather-icon icon="ThumbsDownIcon" size="17"></feather-icon>
          </b-button>
        </div>
        <div class="data-user-proposal">
          <b-avatar :src="own_profile.length > 0 || proposal.user.avatar ? verifyAsset(own_profile.length > 0 ? own_profile : proposal.user.avatar) : ''" size="2.8rem" class="mr-0"></b-avatar>
          <div class="container-username-category">
            <div class="d-flex">
              <span class="avenir-bold text-bold-username-proposal">{{proposal.user_network.name ? proposal.user_network.name : proposal.user_network.username}} </span>
              <b-badge :class="getColorPlan(string_key)" :variant="getColorPlan(string_key)" pill v-if="string_key"> <span class="size-text">{{ string_key }}</span></b-badge>
            </div>
            <span v-if="countries && countries.length > 0 && proposal.country" class="font-tiny">
              {{countries.find((item) => item['alpha-2'] === proposal.country.toUpperCase()).name}}</span>
          </div> 
        </div>
      </div>
    </div>
    <div class="template-footer">  
      <div class="container-price">
        <span class="text-network">{{proposal.user_network.network.toUpperCase()}}</span>
        <span class="text-price avenir-bold">{{proposal.deals.length > 0 ? getPrice(proposal.deals[0]) : ''}}</span>
      </div>
      <div class="container-footer">
        <span class="text-overflow">
          {{ proposal.title }}
        </span>
      </div>
    </div>
  </b-card>
</template>
      
<script>
import { 
  BBadge,
  BCard,
  BImg,
  BAvatar,
  BButton
} from "bootstrap-vue";
import { getIconWhite, getIconColor, getColorPlan } from '@/libs/utils/icons';
import { getFormat, isVideo, capitalize, separatebycomma } from '@/libs/utils/formats';
import { getUserData } from '@/libs/utils/user';
import { verifyAsset } from '@/libs/utils/urls';

export default {
  name: 'cardCategory',
  components: {
    BBadge,
    BCard,
    BImg,
    BAvatar,
    BButton,
  },
  props: {
    proposal: {
      type: Object,
      default: () => {
        return {}
      },
    },
    redirect: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    own_profile: {
      type: String,
      default: ''
    },
    countries: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
  },
  data() {
    return {
      verifyAsset,
      getIconColor,
      getColorPlan,
      getIconWhite,
      getFormat,
      isVideo,
      capitalize,
      separatebycomma,
      user_data: getUserData(),
      string_key: this.proposal.user_network.current_plan && this.proposal.user_network.current_plan.string_key ? this.proposal.user_network.current_plan.string_key : null,
      main_interval: null,
      index_image: 0,
      first_time_transition: false,
      show_animation: false,
      current_image: '',
    }
  },
  created() {
    this.proposal.images.sort((a, b) => a.sort - b.sort);
    this.setInitialCurrentImage();
  },
  methods: {
    setInitialCurrentImage(show_preview = true) {
      const image = this.proposal.images[this.index_image];
      if (image) {
        let url = '';
        if (show_preview) {
          url = (isVideo(image.media) && image.preview.length > 0) && show_preview ? image.preview : image.media
        } else {
          url = image.media;
        }
        const adding_url = verifyAsset(url)
        this.current_image = adding_url;
      }
    },

    getPrice(deal) {
      if (deal.is_brandme_talent) {
        let price = '';
        for (let index = 0; index < deal.range_price; index++) {
          price = `${price}$`
        }
        return price
      }

      return `$ ${separatebycomma(`${deal.price}`.split('.')[0])} USD`
    },
    startInterval() {
      this.setInitialCurrentImage(false);
      this.main_interval = setInterval(() => {
        this.index_image = (this.index_image + 1) % this.proposal.images.length;
        this.first_time_transition = true;
      }, 4000);
    },
    mouseHover() {
      if (!this.show_animation && !this.main_interval) {
        this.show_animation = true;
        this.startInterval();
      }
    },
    mouseLeave() {
      setTimeout(() => {
        this.show_animation = false;
        this.first_time_transition = false;
        this.index_image = 0;
        clearInterval(this.main_interval);
        this.main_interval = null;
        this.setInitialCurrentImage(true);
      }, 10);
    },
    checkVideoEnd() {
      const video = this.$refs.video_animation_proposal;
      if (video) {
        const currentTime = video.currentTime;
        const duration = video.duration;
        const threshold = 4;  
        if (duration - currentTime <= threshold && !this.main_interval) {
          this.startInterval();
        }
      }
    },
    playVideo() {
      const video = this.$refs.video_animation_proposal;
      if (video && video.paused) {
        video.play();
      }
    },
    pauseVideo() {
      const video = this.$refs.video_animation_proposal;
      if (video && !video.paused) {
        video.pause();
      }
    },
    errorLoad() {
      this.current_image = require("@/assets/images/dashboard/imgDefault.png");
    },
    clickCard(proposal) {
      const url = `${window.location.origin}/view-proposal/${proposal.slug}`
      !this.redirect ? this.$router.push({name: 'view_proposal', params: {slug: proposal.slug}}) : window.open(url, '_blank');
      this.$emit('remove_fixed_nav');
    },
    urlProposal(proposal) {
      if (!this.redirect) return `${window.location.origin}/view-proposal/${proposal.slug}`

      return '#'
    },
    getWidth() {
      return `width: ${this.width} !important;`
    },
  },
  watch: {
    index_image(val) {
      if (this.show_animation) {
        const image_media = this.proposal.images[val].media
        this.current_image = verifyAsset(image_media);
        if (isVideo(image_media)) {
          clearInterval(this.main_interval)
          this.main_interval = null;
        }
      }
    }
  }
};
</script>

<style>
</style> 
<style scoped lang="scss">
.container-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 4s;
  transform-origin: center;
  z-index: 1;
}
.image-animation-zoom:hover {
  transform: scale(1.2);
}
.height-310 {
  /* aspect-ratio:  265 / 310; */
  background-color: #ebebeb;
  width: 100%;
  padding-bottom: calc(310 / 265 * 100%);
  position: relative;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 4s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.size-text {
  font-size: 8.5px;
  text-transform: uppercase;
}
.template-footer {
  height: auto !important;
  padding: 7px;
  background-color: white;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.container-main-image {
  overflow: hidden;
  border-radius: 1em;
}
.button-actions-proposal {
  padding: 0.5em;
  background-color: white;
  border-radius: 10em;
}
.font-tiny {
  font-family: 'avenir-light';
  text-align: left;
  font-weight: 500;
}
.ml-04 {
  margin-left: 0.4em;
}
.card-icon-network-x {
  width: 12px;
  margin-bottom: 2px;
}
.followers-inner-badge {
  font-size: 12px;
  line-height: 15px;
  font-family: 'avenir-bold';
}
.badge-network {
  position: absolute;
  top: 1em;
  left: 1em;
  color: white;
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
}
.data-user-proposal {
  position: absolute;
  width: 89%;
  left: 1em;
  bottom: 1em;
  display: flex;
  background-color: rgb(0, 0, 0, 0.65);
  border-radius: 10em;
  padding: 0.6em;
  transition: display 1s;
  transition-delay: 2s;
  z-index: 2;
}
.actions-proposal {
  display: none;
  position: absolute;
  top: 1em;
  right: 1em;
  flex-direction: column;
}
.username-width {
  width: 143px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.verified-badge {
  color: #46ae8a;
  background: #ddf8ee;
}
.pro-badge {
  margin-left: 3px;
  position: absolute;
  color: white;  
  background-color: #902875 !important;
  padding-top: 0.3rem;
}
.text-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212529 !important;
  font-family: 'avenir-medium';
  font-size: .99em;
}
.text-price {
  font-size: 14px;
}
.text-network {
  color: #55a6c4;
  font-size: 10px;
}
.container-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-influencer {
  padding: 0px !important;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.container-username-category {
  margin-left: 10px;
  font-size: 12px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 70%;
  .text-bold-username-proposal {
    display: block;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
}
.container-followers {
  padding-left: 28px;
  white-space: nowrap;
  font-size: .95rem;
}
.container-card-category {
  aspect-ratio:  265 / 390;
  cursor: pointer;
  border-radius: 1em;
  box-shadow: none !important;
  overflow: hidden;
}
.container-card-category:hover .actions-proposal {
  display: flex !important;
  animation: actions 500ms forwards;
}
.container-card-category:hover .data-user-proposal {
  animation: user_box 1s forwards;  
}
.container-card-category:not(:hover) .data-user-proposal {
  animation: user_box_back 500ms forwards;
}
@keyframes actions {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes user_box {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes user_box_back {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>