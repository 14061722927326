<template>
  <b-card class="container-card-network" body-class="p-0" @click="checkRedirect(net)">
    <b-card-text class="network-text">
       <b-img :src="getIconWhite(net)" class="icon-network-x" fluid></b-img>
      {{ nameNetworks(net) }}
    </b-card-text>
    <b-img
      class="container-img-network"
      :src="img"
    ></b-img>
    <div class="container-background-hover"></div>
  </b-card>
</template>
        
<script>
import { BCard, BCardText, BImg, } from "bootstrap-vue";
import { nameNetworks } from '@/libs/utils/formats';
import { getIconWhite, getIconColor } from '@/libs/utils/icons'

export default {
  name: 'cardNetwork',
  props: {
    net: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    img: {
      type: String,
      default: ""
    },
    open_new_tab: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    BImg,
    BCardText,
    BCard,
  },
  data() {
    return {
      getIconColor,
      getIconWhite,
      nameNetworks,
    };
  },
  methods: {
    checkRedirect(net) {
      if (this.open_new_tab) {
        const url = `${window.location.origin}/search-proposal?network=${net.toLowerCase()}`
        window.open(url, '_blank');
      } else {
        this.$router.push({name: 'search_proposal', query: {network: net.toLowerCase()}});
      }
    }
  }
};
</script>
        
<style>
.icon-network {
  font-size: 19px;
}
.icon-network-x {
  width: 19px;
  padding-bottom: 8px;
}
.network-text {
  position: absolute;
  font-weight: bold;
  color: white;
  height: 110px;
  width: 195px;
  border-radius: 5%;
  padding: 81px 20px 20px 20px;
}
.container-card-network {
  height: 110px;
  width: 250px;
  cursor: pointer;
  margin-right: 1em;
  position: relative;
}
.container-card-network:hover .container-background-hover {
  display: block ;
}
.container-img-network {
  width: 100%;
  height: 100%;
  border-radius: 1em;
  object-fit: cover;
}
.container-background-hover {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.17) !important;
  position: absolute;
  top: 0;
  border-radius: 1em;
  display: none;
}
@media (max-width: 459px) {
 .container-card-network { 
  width: 250px;
 }
 .network-text { 
  width: 250px;
 }
}
</style>