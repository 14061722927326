<template>
  <section>
    <div v-if="has_session">
      <error-loading v-if="notData && enable_view" />
      <error-views v-else-if="!enable_view" />
      <account-private v-else-if="account_private" />
      <div v-else
        id="user-profile"
      >
        <div v-if="loading" class="container-loading">
        </div>
        <profile-header
          @updateProfile="updateProfile"
          :show_general="show_general"
          :headerData="headerData"
          :info="dataNet"
          :namePdf="`${section}_${params.username}_${params.network}`"
          :section="section"
          @childDisableEmbed="disableEmbed"
          @childEnableEmbed="enableEmbed"
          @printShortChild="printShort" :influencer_to_campaign="influencer_to_campaign"
          :campaigns_influencer="campaigns_influencer"
          :lists_influencer="lists_influencer"
          :is_updating_profile="is_updating_profile"
          @addedCampaign="getInfluenerIn"
          @update_metrics="updateMetrics"
          :key="change"
          :metrics="metrics"
          :permission_metrics="permission_metrics"
        />
        <!-- profile info  -->
        <section id="profile-info">
          <!-- general -->
          <!-- <profile-calculate :calculateData="calculateData" :riskData="riskData" :key="change"/> -->
          <!-- general -->
          <instagram-view v-if="section ==='instagram'" :data="dataNet" :headerData="headerData" :error="tabData" :showEmbedInstagram="showEmbedInstagram"/>
          <main-youtube v-if="section ==='youtube' && dataNet" :data="dataNet" :error="tabData"></main-youtube>
          <main-tiktok  v-if="section ==='tiktok'" :data="dataNet" :error="tabData" :showEmbedTiktok="showEmbedTiktok"></main-tiktok>
          <main-twitch v-if="section ==='twitch'" :data="dataNet" :error="tabData" :showEmbedTwitch="showEmbedTwitch"/>
          <main-twitter v-if="section ==='twitter'" :data="dataNet" :error="tabData" :username="headerData.username" :showEmbedTwitter="showEmbedTwitter"/>
        </section>
        <!--/ profile info  -->
  
        <short-print v-if="!loading" :format="format_print" :info="dataNet" :avatar="avatar_print" :modalShow="showPrintShort" :key="changePrint"/>
      </div>
    </div>
    <div v-else>
      <main-profile-preview></main-profile-preview>
    </div>
  </section>
  
</template>

<script>
import ProfileHeader from './ProfileHeader.vue'
// import ProfileCalculate from './ProfileCalculate.vue'
import shortPrint from './shortPrint.vue'
import service from '@/services/profile'
import service_profile from '@/services/others'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { getInfluencerIn } from '@/services/search';
import { getMotivationalPhrase } from '@/libs/utils/others';
import { getAssetUrl } from '@/libs/utils/urls';
import { getDatev2 } from '@/libs/utils/times';
import { getUserData } from '@/libs/utils/user';
/* eslint-disable global-require */
export default {
  components: {
    ProfileHeader,
    // ProfileCalculate,
    shortPrint,
    MainTiktok: () => import('@/views/pages/profile/tiktok/MainTiktok.vue'),
    MainTwitch: () => import('@/views/pages/profile/twitch/MainTwitch.vue'),
    MainYoutube: () => import('@/views/pages/profile/youtube/MainYoutube.vue'),
    MainTwitter: () => import('./twitter/MainTwitter.vue'),
    InstagramView: () => import('./instagram/InstagramView.vue'),
    ErrorLoading: () => import('./ErrorLoading.vue'),
    ErrorViews: () => import('./ErrorViews.vue'),
    AccountPrivate: () => import('./AccountPrivate.vue'),
    MainProfilePreview: () => import('./preview/MainProfilePreview.vue')
  },
  props: {
    metrics: {
      type: Object,
      default: () => {
        return {}
      }
    },
    permission_metrics: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },  
  data() {
    return {
      loading: true,
      showPrintShort: false,
      changePrint: 0,
      params: {
        username: this.$route.params.alias,
        network: this.$route.params.network
      },
      headerData: {
        avatar: '',
        username: this.$route.params.alias,
        designation: '',
        main: {
          network: this.$route.params.network,
          uuid: ''
        },
        childs: [],
        estimations: [],
        is_verified: false,
        updated_at: ''
      },
      change: false,
      calculateData: {
        calculate_audience: [],
        calculate_fake: [],
        calculate_followers: [],
        calculate_influence_level: 0
      },
      riskData: {
        level: 0,
        description: ''
      },
      aqs: {
        total: 0,
        description: ''
      },
      audienceData: {
        authenticity: [],
        cities: [],
        countries: [],
        type: []
      },
      profileData: { },
      section: 'general',
      load: true,
      dataNet: {},
      notData: false,
      tabData: false,
      visited: [],
      checkButton: false,
      showEmbedTiktok: true,
      showEmbedTwitter: true,
      showEmbedTwitch: true,
      showEmbedInstagram: true,
      enable_view: true,
      avatar_print: '',
      show_general: true,
      account_private: false,
      format_print: 'pdf',
      influencer_to_campaign: [],
      campaigns_influencer: [],
      lists_influencer: [],
      interval_loading: null,
      is_updating_profile: false,
    }
  },
  computed: {
    has_session() {
      return !!getUserData()
    }
  },
  mounted() {
  },
  created() {
    if (Object.keys(this.metrics).length === 0 && this.has_session) {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      this.checkView()
    } else if (this.has_session) {
      this.handleData(this.metrics);
      this.loading = false;
    }
  },
  methods: {
    updateMetrics(is_buying) {
      (is_buying) ? this.$emit('buy_update_metrics') : this.$emit('update_metrics') 
    },
    reloadPage() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });
      window.location.reload()
    },
    updateProfile() {
      this.is_updating_profile = true;
      service.getChild({network: this.headerData.main.network, username: this.$route.params.alias, update: true}).then(response => {
        this.is_updating_profile = false;
        this.$vs.loading.close()
        clearInterval(this.interval_loading)
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: "top-right",
            listeners: {
              clickButton: this.reloadPage
            },
            props: {
              icon: 'RefreshCwIcon',
              title: this.$t('campaigns.last_update_short'),
              variant: "success",
              text: this.$t('campaigns.infoSuccess'),
              showButton: true,
              textButton: this.$t('update')
            }
          })
        })
        const formData = new FormData();
        formData.append('username', this.headerData.username)
        formData.append('network', this.headerData.main.network)
        this.markCheckedProfile(formData);
        if (this.headerData.main.network === 'instagram') {
          this.dataNet = response
        } else {
          this.dataNet = response.main_child.detail
        }
        this.visited.push(this.headerData.main.network)
        this.section = this.headerData.main.network
      })
        .catch(() => {
          this.tabData = true
          this.section = this.headerData.main.network
        })
    },
    checkView() {
      service.visiteProfile(this.params)
        .then(response => {
          if (response.allowed_visit) {
            this.getSpotlight();
            this.getInfluenerIn()
          } else {
            this.enable_view = false
            setTimeout(() => {
              this.loading = false;
              this.$vs.loading.close()
              clearInterval(this.interval_loading)
            }, 500);
          }
        })
    },
    getInfluenerIn() {
      getInfluencerIn(this.$route.params.alias, this.$route.params.network, 'campaign').then((response) => {
        this.campaigns_influencer = response.results
      });

      getInfluencerIn(this.$route.params.alias, this.$route.params.network, 'lists').then((response) => {
        this.lists_influencer = response.results
      });
    },
    getSpotlight () {
      service.getChild(this.params)
        .then(response => {
          this.handleData(response)

          const formData = new FormData()
          formData.append('username', this.$route.params.alias)
          formData.append('network', this.$route.params.network)
          const self = this;
          this.markCheckedProfile(formData);

          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
          setTimeout(function() {
            self.loading = false;
            self.$vs.loading.close()
            clearInterval(self.interval_loading)
          }, 500);
        })
        .catch(() => {
          const self = this
          this.notData = true
          setTimeout(function() {
            self.loading = false;
            self.$vs.loading.close()
            clearInterval(self.interval_loading)
          }, 500);
        })

    },
    handleData(response) {
      if (response.data && response.data.response.code === 'data.PrivateAccount') {
        this.account_private = true;
        this.loading = false;
        return;
      }
      this.show_general = response.show_general
      this.headerData.followers = response.calculate_followers.data.find((item) => item.network === response.main_child.network).followers
      this.headerData.username = response.username
      this.headerData.main.network = response.main_child.network
      this.headerData.main.uuid = response.main_child.uuid
      this.headerData.childs = response.other_child
      this.headerData.designation = response.bibliography
      this.calculateData.calculate_audience = response.calculate_audience
      this.calculateData.calculate_fake = response.calculate_fake
      this.calculateData.calculate_followers = response.calculate_followers
      this.calculateData.calculate_influence_level = response.calculate_influence_level
      this.calculateData.info = response.main_child && response.main_child.detail && response.main_child.detail ? response.main_child.detail : {}
      this.riskData.level = response.risk_level
      this.riskData.description = response.risk_level_description
      this.visited.push(this.params.network)
      // TODO: Estmaciones para otras redes
      this.headerData.estimations = response.main_child.detail.estimation;
      this.headerData.updated_at = getDatev2(new Date(response.main_child.updated_at))
      this.change = !this.change;

      this.section = response.show_general ? 'general' : response.main_child.network

      const object_influencer = {}
      object_influencer.influencer = this.$route.params.alias;
      object_influencer.username = this.$route.params.alias;
      object_influencer.network = this.params.network;

      object_influencer.image = response.image_url === null ? getAssetUrl(response.file_for_profile) : response.image_url;
      object_influencer.followers = response.calculate_followers.data.find((item) => item.network === response.main_child.network).followers;
      this.influencer_to_campaign.push(object_influencer)


      if (this.params.network === 'instagram') {
        this.headerData.is_verified = response.main_child.detail.is_verified;
        this.dataNet = response
      } else {
        this.dataNet = response.main_child.detail
        this.headerData.is_verified = response.main_child.detail.report.basic ? response.main_child.detail.report.basic.is_verified : false;
        if (this.params.network === 'youtube') {
          this.headerData.username = response.main_child.detail.report.basic.title
          this.headerData.username_channel_id = response.username;
        }
      }

      if (this.params.network === 'youtube') {
        if (this.dataNet.report_state === 'NO_VIEWS') {
          this.notData = true
          this.loading = false;
          return
        }
      }

      if (response.image_url === null) {
        this.headerData.avatar = getAssetUrl(response.file_for_profile);
      } else {
        this.headerData.avatar = response.image_url
      }
    },
    printShort(avatar, format) {
      this.format_print = format
      this.avatar_print = avatar;
      this.showPrintShort = true;
      this.changePrint += 1;
    },
    getTypeData (data) {
      const keys = data.keys()
      return data.map((item, index) => {
        const obj = []
        obj.push({name: keys[index], value: (item * this.calculateData.calculate_followers.total / 100), percent: item})
        return obj
      })
    },
    markCheckedProfile (formData) {
      service.checkProfile(formData)
        .then(() => {})
    },
    disableEmbed(data) {
      switch (data.section) {
      case 'tiktok':
        this.showEmbedTiktok = false;
        break;
      case 'twitter':
        this.showEmbedTwitter = false;
        break;
      case 'twitch':
        this.showEmbedTwitch = false;
        break;
      case 'instagram':
        this.showEmbedInstagram = false;
        break;
      }
    },
    enableEmbed(data) {
      switch (data.section) {
      case 'tiktok':
        this.showEmbedTiktok = true;
        break;
      case 'twitter':
        this.showEmbedTwitter = true;
        break;
      case 'twitch':
        this.showEmbedTwitch = true;
        break;
      case 'instagram':
        this.showEmbedInstagram = true;
        break;
      }
    }
  }
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>

<style type="text/css">
  .container-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F2F2F3;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
