import config from '@/services/config'
import axios from './axios_interceptors'
import { tokenExpired, getUserData} from '@/auth/utils'

export default {
  getResults (datos) {
    return axios.post(`${config.urlPhp}/api/v1/buscador/influencers`, datos, {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  freeSearcher(datos) {
    return axios.post(`${config.urlPhp}/api/v2/buscador/gratis`, datos, {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  freeLookalikeSearcher(datos) {
    return axios.post(`${config.urlPhp}/api/v2/lookalike`, datos, {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  
  getHistorySearches(data) {
    return axios.post(`${config.urlCore}/api/v3/searcher/history/`, data, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getMainLocations() {
    return axios.get(`${config.urlCore}/api/v3/searcher/main-locations/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  toggleFavoriteSearch(searcher_uuid) {
    return axios.get(`${config.urlCore}/api/v3/searcher/toggle-favorite/${searcher_uuid}/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  deleteHistorySearch(searcher_uuid) {
    return axios.get(`${config.urlCore}/api/v3/searcher/history-delete/${searcher_uuid}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  toggleFavoriteHistorySearch(searcher_uuid) {
    return axios.get(`${config.urlCore}/api/v3/searcher/toggle-favorite/${searcher_uuid}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  changeTitleHistory(searcher_uuid, data) {
    return axios.post(`${config.urlCore}/api/v3/searcher/change-title/${searcher_uuid}/`, data, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  saveLogSearch (datos) {
    return axios.post(`${config.urlCore}/api/v1/finder/log/search/`, datos, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getFirstPage (datos) {
    return axios.post(`${config.urlCore}/api/v1/finder/searcher/`, datos, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  analize (name, net = '') {
    const url = `${config.urlCore}/api/v1/finder/suggester/`
    return axios.post(url,
      {
        query: name,
        networks: net
      },
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })

  },
  getLocation (loc) {
    return axios.get(`${config.urlBase}/search-location`,
      {
        params:{
          query: loc
        }
      })
      .then(response => response.data.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getCategories(network) {
    return axios.get(`${config.urlCore}/api/v1/finder/get/taxonomy/${network}/`,
      {
        headers: getUserData() ? { Authorization: `Bearer ${localStorage.getItem('accessToken')}`} : {}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
}

export const getMainInfluencers = (country) => {
  const url = `${config.urlPhp}/api/v2/main/influencers`
  return axios.post(url,
    {
      country
    }, {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}

export const getSummaryInfluencer = (network, username) => {
  const url = `${config.urlPhp}/api/v2/summary-influencer/${network}/${username}`
  return axios.get(url,
    {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}

export const getHistorySuscribers = (network, username) => {
  const url = `${config.urlPhp}/api/v2/history/${network}/${username}`
  return axios.get(url,
    {
      headers: {
        'brandme-key': process.env.VUE_APP_BRANDME_KEY,
      }
    })
    .then(response => response.data.data)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    })
}

export const getInfluencerIn = (username, network, objecttype) => {
  return axios.get(`${config.urlCore}/api/v1/finder/searcher/influencer-in/${username}/${network}/${objecttype}/`,
    {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    });
}

export const addFavoritesInlfuencer = (data) => {
  return axios.post(`${config.urlCore}/api/v3/lists/add-favorites/`,
    data,
    {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
    .catch(error => {
      if (error.response.status === 401) tokenExpired()
      return error.response
    });
}