<template>
  <section>
    <b-card>
      <div class="w-100">
        <b-badge class="badge-antiquity" v-if="date_antiquity">
          {{date_antiquity}}
        </b-badge>
        <b-skeleton type="input" class="skeleton-antiquity col-2" v-else></b-skeleton>
        <current-membership :status_plan_dad="status_plan" :loaded_service="status_loaded" class="col-12 mb-2" :key="current_membership_key"></current-membership>

        <b-tabs v-model="tab_memberships" class="">
          <b-tab :title="$t('memberships.memberships')">
            <div class="w-100">
              <component :is="vue_horizontal" responsive class="horizontal p-0" :displacement="0.3" snap="center" v-if="loader_membership">
                <card-membership 
                  v-for="(item, index) in results"
                  :key="item.uuid"
                  :plan="item"
                  :status_plan="status_plan"
                  :number_plan_selected="index"
                  @update_status="getStatusPlan"
                  :trial_info="trial_info"
                  class="container-card-membership"
                ></card-membership>
                
              </component>

              <component :is="vue_horizontal" responsive class="horizontal" :displacement="0.3" snap="center" v-else>
                <div v-for="index in 3" :key="index" class="w-auto">
                  <b-card
                    footer-class="btn-plan-footer"
                    :header-class="`header-card-membership p-0`"
                    :class="`container-card card-skeletons`"
                  >
                    <template #header>
                      <b-skeleton class="p-17"></b-skeleton>
                    </template>
                    <div class="container-data">
                      <div class="mt-1">
                        <div class="mb-1 d-flex justify-content-center align-items-end">
                          <b-skeleton class="h-45 col-5"></b-skeleton>
                        </div>
                        <div class="d-flex flex-column text-left">
                          <span class="avenir-medium border-bottom pb-1 mb-1">
                            <b-skeleton class="mb-05 col-12"></b-skeleton>
                            <b-skeleton class="mb-05 col-11"></b-skeleton>
                            <b-skeleton class="mb-05 col-12"></b-skeleton>
                          </span>
                          
                          <strong class="w-100 text-left mb-1">{{$t('creator.include')}} </strong>
                          <div class="border-bottom mb-1 pb-1">
                            <b-skeleton class="mb-05 col-4"></b-skeleton>
                            <b-skeleton class="mb-05 col-1"></b-skeleton>
                          </div>
                          <div class="mb-1 pb-1 border-bottom">
                            <b-skeleton class="col-5 mb-2"></b-skeleton>
                            <b-skeleton class="col-6 mb-2"></b-skeleton>
                            <b-skeleton class="col-7 mb-2"></b-skeleton>
                            <b-skeleton class="col-4 mb-2"></b-skeleton>
                            <b-skeleton class="col-12 mb-2"></b-skeleton>
                            <b-skeleton class="col-12 mb-2"></b-skeleton>
                            <b-skeleton class="col-10 mb-2"></b-skeleton>
      
                          </div>
                          <div>
                            <b-skeleton class="col-9 mb-2"></b-skeleton>
                            <b-skeleton class="col-8 mb-2"></b-skeleton>
      
                            <b-skeleton type="button" class="w-100 mt-4 h-43"></b-skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card>
                </div>
              </component>
            </div>
          </b-tab>
          <b-tab :title="$t('memberships.packages')" v-if="is_brand && !is_free">
            <div class="w-100" >
              <component :is="vue_horizontal" id="horintal-vue-packs" responsive class="horizontal" :displacement="0.3" snap="center">
                <div v-for="i in plan_packs" :key="i.name" class="w-auto">
                  <card-package :plan="i"></card-package>
                </div>
              </component>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </b-card>

  </section>
</template>

<script>
import {
  BCard,
  BSpinner,
  BBadge,
  BTabs,
  BTab,
  BSkeleton,
} from 'bootstrap-vue'

import cardMembership from '@/views/pages/proposals/cardMembership.vue'
import service from "@/services/proposals";
import others_service from "@/services/others";
import { getUserData, calculateAntiquity } from '@/libs/utils/user';
import { getPriceActions } from '@/services/ia';
import { showConfetti } from '@/libs/utils/others';
import store from '@/store'

export default {
  components: {
    BBadge,
    BCard,
    BSpinner,
    cardMembership,
    BTabs,
    BTab,
    BSkeleton,
    CurrentMembership: () => import('./CurrentMembership.vue'),
    cardPackage: () => import('@/views/components/settings/cardPackage.vue')
  },

  data() {
    return {
      date_joined: '',
      date_antiquity: '',
      calculateAntiquity,
      results: [],
      status_plan: {},
      loader_membership: false,
      vue_horizontal: false,
      tab_memberships: 0,
      price_per_credit: 0,
      trial_info: {},
      status_loaded: false,
      current_membership_key: 0,
    };
  },
  props: {
    profile: {
      type: Object,
    },
    loaded: {
      type: Boolean,
      default: false,
    }
  },
  created() {
    const params = this.$route.params;
    if (params.tab && params.tab === 'packages') this.tab_memberships = 1;
    this.getPlansMembers();
    this.getStatusPlan();
    this.getPriceActions();
  },

  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default
    this.updateAntiquityString();
  },
  beforeMount() {
    const query_param = this.$route.query;
    if (query_param?.['plan-success'] === 'true') {
      showConfetti();
      this.$router.replace({name: 'pages-account-setting', params: {section: 'membership'}});

      this.getStatusPlan()
    }
    this.getApplyTrial();
  },
  computed: {
    plan_packs() {
      const array = [
        {
          name: this.$t('creator.packageBasic'),
          options: [`10 ${this.$t('creator.reports')}`],
          price: this.price_per_credit * 10,
          credit: 10,
          background_card: "card-basic"
        },
        {
          name: this.$t('creator.packagePro'),
          options: [`100 ${this.$t('creator.reports')}`],
          price: this.price_per_credit * 100,
          credit: 100,
          background_card: "card-pro"
        },
        {
          name: this.$t('creator.packageBusiness'),
          options: [`500 ${this.$t('creator.reports')}`],
          price: this.price_per_credit * 500,
          credit: 500,
          background_card: "card-business"
        }
      ];
      return array;
    },
    is_free() {
      return getUserData().current_plan.name === 'Free'
    },
    is_brand() {
      return getUserData() && getUserData().main_group.name !== 'creator'
    }
  },
  watch: {
    '$i18n.locale': 'updateAntiquityString',
    loaded(val) {
      if (val) {
        this.updateAntiquityString();
      }
    }
  },
  methods: {
    getPriceActions() {
      getPriceActions().then((response) => {
        this.price_per_credit = response.price_per_credit;
      })
    },
    getApplyTrial() {
      others_service.applyTrial().then((response) => {
        this.trial_info = response;
      });
    },
    updateAntiquityString() {
      const antiquity = calculateAntiquity(this.profile.date_joined);
      this.date_antiquity = `${this.$t('creator.antiquity')} ${antiquity.years} ${this.$t('creator.years')}, ${antiquity.months} ${this.$t('creator.months')}, ${antiquity.days} ${this.$t('creator.days')}.`;
    },
    getPlansMembers() {
      service.getPlan().then((response) => {
        this.results = response.results;
        const user_data = getUserData();
        this.current_membership_key += 1;
        if (user_data.current_plan.name !== 'Gestión') this.updatePlan(this.results.filter((i) => i.is_active)[0]);
        setTimeout(() => {
          this.loader_membership = true;
        }, 1000);
      });
    },
    updatePlan(active_plan) {
      const user_data = getUserData();
      if (active_plan && active_plan.uuid !== user_data.current_plan.uuid) {
        user_data.current_plan = active_plan;
        localStorage.setItem('userData', JSON.stringify(user_data))
      }
    },
    getStatusPlan() {
      service.statusPlan().then((response) => {
        this.status_plan = response;
        this.status_loaded = true;
        if (response.status === 'trialing') store.commit('main_store/SET_TRIAL_PERIOD', {trial: true, current_period_end: response.current_period_end, renew_soon: response.renew_soon})
      });
    },

  },
}
</script>
<style lang="scss">
#horintal-vue-packs .v-hl-container {
  padding: 0.2em 1em;
}
.card-skeletons {
  border: 1px solid rgb(206, 212, 218) !important;
  margin: 10px;
  width: 330px;
  border-radius: 1rem;
  .p-17 {
    height: 54px;
    width: 100%;
    overflow: hidden !important;
  }

  .h-45 {
    height: 45px
  }

  .h-43 {
    height: 43.75px
  }
  .mb-05 {
    margin-bottom: 0.5em;
  }
  .ml-05 {
    margin-left: 0.5em;
  }
}
</style>
<style scoped lang="scss">
.badge-antiquity {
  background: linear-gradient(90deg, #5675b8 0%, #05a3bc 100%);
  padding: 10px;
  margin: 16px;
  line-height: 1.5em;
  white-space: wrap;
}
.skeleton-antiquity {
  padding: 10px;
  margin: 16px;
}
.container-card-membership {
  width: 330px !important;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.container-loader-membership {
  min-height: 500px;
  width: 100%;
}
.loader-membership {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-membership {
  height: 6em;
  width: 6em;
  color: #acacac;
}
</style>